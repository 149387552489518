import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import {
  Grid, Link
} from "@material-ui/core";
import Axios from "axios";
import AuthService from "../../../utils/services/auth-service";
import { DEFAULT_ERROR_CALLBACK } from "../../../utils/app-util";
import { useHistory } from "react-router-dom";
import Routes from "../../../routes/routes";
import Loader from "../../../components/misc/loader";
import { useSetRecoilState } from "recoil";
import { NotificationManager } from "react-notifications";
import { LoginState } from '../../../states';

import "react-phone-input-2/lib/style.css";
import "../login-page.scss";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

const PhoneNumberPage = ({ setOpenOtpPage }) => {
  // set document title
  useDocumentTitle("Login");

  const [otpLogin, setOtpLogin] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inValidPhone, setInValidPhone] = useState(false);
  const [inValidPassword, setInValidPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [inValidEmail, setInvalidEmail] = useState(false);
  const [imgData, setImgData] = useState();
  const [intendedUrl, setIntendedUrl] = useState(null);

  const setLoginCreds = useSetRecoilState(LoginState);

  const history = useHistory();

  // regex for the email id
  useEffect(() => {
    if (email) {
      const isValidEmail = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      isValidEmail ? setInvalidEmail(false) : setInvalidEmail(true)
    }
  }, [email])

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        // setImgDatabackend(resp1?.data[0]?.loginBgBackEnd)
        setImgData(resp1?.data[0]?.logo)
      }
    });
  }, []);

  useEffect(() => {
    const storedIntendedUrl = localStorage.getItem('intendedUrl');
    if (storedIntendedUrl) {
      setIntendedUrl(storedIntendedUrl);
      localStorage.removeItem('intendedUrl'); // Clear the stored URL after retrieving it
    }
  }, []);

  const LoginThroughPassword = () => {
    setIsLoading(true);
    const payload = {
      mobileNumber: countryCode + mobileNumber,
      password: password,
      countryCode: countryCode,
    };
    AuthService.login(payload).subscribe(
      () => {
        if (intendedUrl) {
          history.replace(intendedUrl);
        } else {
          history.replace(Routes.MAIN);
        }
        setIsLoading(false);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setIsLoading(false);
      }
    );
  };

  const onQrScanBtnClicked = () => {
    history.push(Routes.FORGETPASSWORD);
  };

  const sendOtpToUser = () => {
    const payload = {
      mobileNumber: mobileNumber,
      countryCode: countryCode,
      email: email,
      password: "",
    };
    AuthService.sendOtp(payload).subscribe(
      () => {
        NotificationManager.success('OTP Sent Successfully', "", 5000);
        setTimeout(() => {
          setOpenOtpPage(true);
        }, 2000);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoginCreds({
      mobileNumber: mobileNumber,
      countryCode: countryCode,
      email: email,
    })
    if (!otpLogin) {
      if (mobileNumber && password) {
        !isLoading && LoginThroughPassword();
      } else {
        !mobileNumber && setInValidPhone(true)
        !password && setInValidPassword(true)
      }
    }
    else {

      // if (mobileNumber || (email && !inValidEmail)) {
      if (mobileNumber && (email && !inValidEmail)) {
        sendOtpToUser();
      } else {
        !mobileNumber && setInValidPhone(true);
        !email && setInvalidEmail(true);
      }
    }
  };

  const handleChangePhoneNumber = (value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setInValidPhone(false);
  };

  const handleChangePassword = (event) => {
    setInValidPassword(false);
    setPassword(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const switchLoginForm = () => {
    setOtpLogin(!otpLogin);
  };

  return (
    <>
      <div className='login-page__login-box'>
        <img className="login-page__logo" src={imgData} alt="Login logo" />
      </div>
      <div className='login-page__login-box'>
        <div className='login-page__headline'>Log in to your account</div>
        <div className='login-page__text-content'>
          Continue with your mobile number as you proceed to login.
        </div>
      </div>
      <form
        className='login-page__login-box login-page__login-form'
        onSubmit={handleSubmit}
      >
        <div className='login-page__form-row'>
          <label className='login-page__label' htmlFor='phone-input'>
            Mobile Number (Required)
          </label>
          <PhoneInput
            country={"in"}
            enableSearch
            inputClass='login-page__phone-input'
            id='phone-input'
            countryCodeEditable={false}
            placeholder='Enter Mobile'
            onChange={handleChangePhoneNumber}
          />
          {inValidPhone && (
            <span className='login-page__validation-error'>
              Phone Number is required
            </span>
          )}
        </div>
        {otpLogin && (
          <div className='login-page__form-row'>
            <label className='login-page__label' htmlFor='email-input'>
              {/* Email Id (Optional) */}
              Email Id
            </label>
            <input
              id='email-field'
              type='email'
              // required
              className='login-page__password-input'
              placeholder='Enter Email'
              onChange={handleChangeEmail}
              value={email}
            />

            {


              inValidEmail && (
                <span className='login-page__validation-error'>
                  InValid Email
                </span>
              )}
          </div>
        )
        }
        {!otpLogin && (
          <div className='login-page__form-row'>
            <label className='login-page__label' htmlFor='password-field'>
              Password
            </label>
            <input
              id='password-field'
              type='password'
              className='login-page__password-input'
              placeholder='Enter Password'
              onChange={handleChangePassword}
              value={password}
            />
            {inValidPassword && (
              <span className='login-page__validation-error'>
                Password is required
              </span>
            )}
          </div>
        )}
        {!otpLogin && (
          <Grid item xs>
            <Link
              onClick={onQrScanBtnClicked}
              variant="body2"
              style={{
                float: 'right',
                marginBottom: '10px',
                marginTop: '-8px',
                fontSize: 'small'
              }}>
              Forgot password?
            </Link>
          </Grid>
        )}
        <button
          className='login-page__login-btn login-page__form-row'
          type='submit'
        >
          {isLoading ? (
            <Loader color={"#fff"} size={"35px"} />
          ) : otpLogin ? (
            "Send Otp"
          ) : (
            "Login"
          )}
        </button>
      </form>
      <div className='login-page__switch-btn' onClick={switchLoginForm}>
        {otpLogin ? "Login with Password" : "Login with Otp"}
      </div>
    </>
  );
};
export default PhoneNumberPage;
