import React, { useState, useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from '../../components/layout/header';
import Tab from '../appointment/tab';
import JobModal from './JobModal';
import { GetJobs, AddJob } from '../../apis/JobAPI';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import JobGrid from './JobGrid';
import { useQueryClient } from '@tanstack/react-query';
import { TextField } from '@mui/material';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        borderRadius: "50%",
        width: "2.5em",
        height: "2.5em",
    },
    modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 500,
        minWidth: 375,
        width: "100%",
        height: "auto",
        background: theme.palette.bg,
        boxShadow: 24,
        padding: "2%",
        borderRadius: "10px",
    },
    style_1: {
        width: "100%",
        marginLeft: "2%",
        marginRight: "4%",
        backgroundColor: "white",
        borderRadius: 14,
    },
    prescriptionTitle: {
        fontSize: "0.8rem",
        fontWeight: "700",
        opacity: "0.7",
        lineHeight: "normal",
        marginBottom: "20px",
        marginTop: "30px",
    },
}));

const Job = () => {
    // set document title
    useDocumentTitle("Jobs");

    const classes = useStyles();
    const theme = useTheme();
    
    const [tabIndex, setTabIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAssignedTo, setIsAssignedTo] = useState(true);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetDate, setResetDate] = useState(false);

    // Initialize query client, mutation for adding a job and fetching jobs
    const queryClient = useQueryClient();
    const addJob = AddJob();
    const { data: jobs, isLoading: jobsLoading, refetch: refetchJobs } = GetJobs();

    const user  = useSelector(state => state.user);

    const handleSubmit = (payload) => {
        addJob.mutate(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['jobs'] });
            },
        });
    };

    useEffect(() => {
        if (jobs?.data) {
            let filtered = [];
            if (tabIndex === 1) {
                // Jobs assigned by the user
                refetchJobs();
                filtered = jobs?.data.filter(job => job?.assignedBy?.slug === user?.slug);
            } else if (tabIndex === 0) {
                // Jobs assigned to the user
                refetchJobs();
                filtered = jobs?.data.filter(job => job?.assignedTo?.slug === user?.slug);
            }
            if (searchQuery) {
                filtered = filtered.filter(job => {
                    const searchLower = searchQuery.toLowerCase();
                    const matchesCommonFields = 
                        job?.taskName?.toLowerCase().includes(searchLower) ||
                        job?.taskDescription?.toLowerCase().includes(searchLower) ||
                        job?.status?.toLowerCase().includes(searchLower) ||
                        job?.comment?.toLowerCase().includes(searchLower);

                    if (tabIndex === 0) {
                        // "Assigned To Me" tab
                        return matchesCommonFields || job?.assignedBy?.name?.toLowerCase().includes(searchLower);
                    } else if (tabIndex === 1) {
                        // "Assigned By Me" tab
                        return matchesCommonFields || job?.assignedTo?.name?.toLowerCase().includes(searchLower);
                    }
                    return matchesCommonFields;
                });
            }
            // Sort jobs: Overdue first, then Assigned, then Completed
            filtered.sort((a, b) => {
                const statusOrder = { 'overdue': 0, 'assigned': 1, 'completed': 2 };
                const statusA = statusOrder[a.status.toLowerCase()] ?? 3;
                const statusB = statusOrder[b.status.toLowerCase()] ?? 3;

                if (statusA !== statusB) {
                    return statusA - statusB;
                }

                // If statuses are the same, sort by due date
                const dueDateA = new Date(a.dueDate);
                const dueDateB = new Date(b.dueDate);

                const dateA = new Date(dueDateA.getFullYear(), dueDateA.getMonth(), dueDateA.getDate());
                const dateB = new Date(dueDateB.getFullYear(), dueDateB.getMonth(), dueDateB.getDate());

                if (dateA !== dateB) {
                    return dateA - dateB;
                }

                // If due dates are the same, sort by latest start date
                const startDateA = new Date(a.startDate);
                const startDateB = new Date(b.startDate);

                const dateStartA = new Date(startDateA.getFullYear(), startDateA.getMonth(), startDateA.getDate());
                const dateStartB = new Date(startDateB.getFullYear(), startDateB.getMonth(), startDateB.getDate());

                return dateStartA - dateStartB; // Latest start date first
            });
            setFilteredJobs(filtered);
        }
    }, [jobs, tabIndex, user.slug, refetchJobs, setFilteredJobs, searchQuery]);


    const onTabClicked = (index) => {
        setTabIndex(index);
        setIsAssignedTo(index === 0);
        setResetDate(prev => !prev);
    }

    const onAddNewJob = () => {
        setIsModalOpen(true);
    }

    if (jobsLoading) {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress />
        </div>
    }

    return (
      <>
        <Header title="Jobs" onAddNew={onAddNewJob} onJobSearch={true} />
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", width: "100%" }}>
                <Tab
                key={0}
                index={0}
                currentIndex={tabIndex}
                title={`Assigned To Me`}
                onTabClicked={onTabClicked}
                />
                <Tab
                key={1}
                index={1}
                currentIndex={tabIndex}
                title={`Assigned By me`}
                onTabClicked={onTabClicked}
                />
            </div>
            <TextField
                variant="outlined"
                placeholder="Search Jobs"
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                    marginLeft: "auto",
                    marginRight: "20px",
                    width: "30%",
                    '& .MuiOutlinedInput-root': {
                        height: "40px",
                    },
                    '& .MuiOutlinedInput-input': {
                        padding: "10px",
                    },
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: theme?.palette?.primary?.main,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: theme.palette.text.secondary,
                        '&.Mui-focused': {
                            color: theme?.palette?.primary?.main,
                        },
                    },
                }}
            />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <JobGrid 
            rows={filteredJobs} 
            isAssignedToUser={isAssignedTo} 
            resetDate={resetDate} 
          />
        </div>
        <JobModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          title={"Assign Job"}
          onSubmit={handleSubmit}
          data={filteredJobs}
          isAssignedTo={isAssignedTo}
          newJob={true}
        />
      </>
    );
};

export default Job;
