import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";

import React, { useState, useEffect, useMemo, useCallback } from "react";
import Calendar from "./calendar/calendar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import AppointmentsCardSection from "./appointments-card-view/appointments-card-section";
import AppointmentList from "./appointment-list";
import Header from "../../components/layout/header";
import DataService from "../../utils/services/data-service";
import Tab from "./tab";
import { useHistory, useLocation } from "react-router-dom";
import { Tooltip, IconButton, withStyles } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Routes from "../../routes/routes";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import AppConstants from "../../utils/app-constants";
import isAuthorised from "../../utils/role-util";
import Loader from "../../components/misc/loader";
import moment from "moment";
import { orderBy } from "lodash";
import { AppointmentContext } from "../../context/appointment";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import soundFileDoorBell from "../../assets/doorbell.wav";
import RescheduleDialog from "./reschedule-dialog";
import useGlobalStyles from "../../utils/global-style";
import LeaveLateDialog from "./leave-late-dialog";
import AddDiscountDialog from "./add-discount-dialog";
import PrintInfoDialog from "./print-info-dialog";
import {
  AppointmentsState,
  AppointmentsStatusState,
  CheckedInAudioState,
} from "../../states";
import AuthService from "../../utils/services/auth-service";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import { format } from "date-fns";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import AdmittedList from "./admited-list";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup, FormControlLabel } from "@material-ui/core";
import { useSelector } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    borderRadius: "50%",
    width: "2.5em",
    height: "2.5em",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "20px",
    marginTop: "30px",
  },
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "0.6rem",
  },
}))(Tooltip);

const Appointment = () => {
  // set document title
  useDocumentTitle("Appointments");
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open_notify, setOpenNotify] = React.useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [patientSlugmodal, setpatientSlug] = useState("");
  const handleOpen = (id) => (setOpen(true), setAppointmentId(id));
  const handleClose = () => setOpen(false);
  const handlenofify = (pslug) => (setOpenNotify(true), setpatientSlug(pslug));
  const handleClosenotify = () => setOpenNotify(false);
  const [openOne, setOpenOne] = React.useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState("");

  const user = AuthService.getUser();
  const isPrimaryProfile = useSelector(state => state?.switchProfile?.isPrimaryProfile);
  const switchedProfileInfo = useSelector(state => state.switchProfile?.switchedProfileInfo);

  const [notifyDate, setNotifyDate] = useState(null);

  const handleDateChange = (date) => {
    setNotifyDate(date);
  };

  const handleSubmitNotifyDate = async () => {
    const formData = new FormData();
    formData.append("date", notifyDate.format());
    formData.append("doctorSlug", selectedDoctor);

    try {
      const responseData = await Axios({
        method: "get",
        url: `${process.env.REACT_APP_API2_HOST}notify_date_patients?date=${notifyDate.format()}&doctorSlug=${selectedDoctor ?? user?.slug}`,
        // url:`${process.env.REACT_APP_API2_HOST}notify_date_patients`,
        // data:formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (responseData) {
        NotificationManager.success(responseData.data.message);
        setOpenOne(false);
      }
    } catch (error) {
      console.error("Error occurred while sending the request:", error);
    }
  };

  const handleClickOpenOne = () => {
    setOpenOne(true);
  };

  const handleCloseOne = () => {
    setOpenOne(false);
  };

  const fetchTabIndex = () => {
    const tab = sessionStorage.getItem("appointmentTab");
    return tab ? parseInt(tab) : 0;
  };

  const [isListView, setIsListView] = useState(true);
  const appntmentCntxt = AppointmentContext.useContainer();
  const [tabIndex, setTabIndex] = useState(fetchTabIndex());
  const [currentStatus, setCurrentStatus] = useState(null);
  const [data, setData] = useState([]);

  const [calendarData, setCalendarData] = useState([]);

  const [allDoctors, setAllDoctors] = useState();

  const [allPatients, setAllPatients] = useState(null);

  const [selectedPatient, setSelectedPatient] = useState("");
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [btnToggle, setButtonToggle] = useState(false);
  const [printLoading, setIsPrintLoading] = useState(false);
  const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [currentRowInfo, setCurrentRowInfo] = useState({});
  const [currentDate, setCurrentDate] = useState(new Date().valueOf());
  const globalClass = useGlobalStyles();
  const setAppointments = useSetRecoilState(AppointmentsState);
  const setAppointmentsStatus = useSetRecoilState(AppointmentsStatusState);
  const checkinAudio = useRecoilValue(CheckedInAudioState);

  const location = useLocation();
  const getFilterObj = ({ date, doctorSlug, patientSlug, status }) => {
    return {
      date: date
        ? {
            type: "equals",
            filter: date,
            filterType: "date",
          }
        : null,
      doctorSlug: doctorSlug
        ? {
            filterType: "set",
            values: [doctorSlug],
          }
        : null,
      patientSlug: patientSlug
        ? {
            filterType: "set",
            values: [patientSlug],
          }
        : null,
      status: status ? { values: status, filterType: "set" } : null,

      custom: {
        filterType: "exact",
        filter: "noTokenFilter",
      },
    };
  };

  useEffect(() => {
    onDoctorSelected();
  }, []);

  const [filter, setFilter] = useState(
    getFilterObj({
      date: currentDate,
      doctorSlug: selectedDoctor,
      patientSlug: selectedPatient,
      status: null,
    })
  );

  // to handle profile switching
  useEffect(() => {
    if(!isPrimaryProfile) {
      setFilter((prev) => ({
        ...prev,
        doctorSlug: { filterType: "set", values: [switchedProfileInfo?.doctorSlug] }
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        doctorSlug: null
      }))
    }
  }, [isPrimaryProfile])

  const onDoctorSelected = (val) => {
    setFilter((prev) => ({
      ...prev,
      doctorSlug: val ? { filterType: "set", values: [val] } : null,
    }));

    setSelectedDoctor(val);
  };
  const [totalElements, setTotalElements] = useState(0);
  const [totalCheckedElements, setTotalCheckedElements] = useState(0);
  const [totalCancelledElements, setTotalCancelledElements] = useState(0);
  const [totalAdmittedElements, setTotalAdmittedElements] = useState(0);
  const [totalPageElements, setTotalPageElements] = useState(0);
  const [callNext, setCallNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [customLoge, setCustomLoge] = useState("");
  const [addLoadingStatus, setAddLoadingStatus] = useState(false);
  const [showRecord, setShowRecord] = useState(null);
  const [checkinAudioStatus, setCheckinAudioStatus] = useState(null);

  const [apiStatus, setApiStatus] = useState(false);
  const [refresh, setRefresh] = useState(null);

  const handleCustomLoge = (e) => {
    const value = e.target.value;
    setCustomLoge(() => value);
  };

  //const users = JSON.parse(localStorage.getItem("USERS"))
  const handleSubmit = async (event) => {
    setAddLoadingStatus(true);
    const formData = new FormData();
    formData.append("customStatus", customLoge);
    formData.append("appointmentId", appointmentId);
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}/add_appointment_custom_status`,
        data: formData,
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.message.toString() === "Added Successfully!") {
        NotificationManager.success(response.data.message);
        setAddLoadingStatus(false);
        setOpen(false);
      } else {
        NotificationManager.error(response.data.message);
        setAddLoadingStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sortingOptions = [
    { label: "Date & Time", value: "dateTime" },
    { label: "Queue", value: "queue" },
  ];

  useEffect(() => {
    const isQueueUser = user.roleName === AppConstants.ROLE.QUEUE;
    if (!isQueueUser) {
      setActiveTab(AuthService.getActiveTab());
    }
  }, [user.roleName]);
  const [admittedcounts, setAdmittedCounts] = useState(0);

  useEffect(() => {
    (async () => {
      Axios.get(
        `${process.env.REACT_APP_API2_HOST}/get_show_record?id=${user.slug}`
      ).then((resp) => {
        if (resp) {
          setShowRecord(resp?.data?.data?.showRecord);
          setCheckinAudioStatus(resp?.data?.data?.checkinAudio);
        }
      });
    })();
  }, []);

  const getSortObj = (colId) => [{ colId, sort: "ASC" }];
  const [sortOptionSelected, setSortOptionSelected] = useState(
    sortingOptions[0].value
  );

  const [sortFilter, setSortFilter] = useState(
    getSortObj(sortingOptions[0].value)
  );

  const pageSize = 50;

  const fetchAppointments = () => {
    if(refresh === null) setLoading(true);

    if (
      (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
      (showRecord === false ||
        selectedDoctor !== null ||
        selectedDoctor !== "" ||
        selectedDoctor !== undefined)
    ) {
      const appntmentSubscription = DataService.polledFetchList(
        "appointment/search",
        // `get_appointment`,
        page,
        pageSize,
        filter,
        sortFilter
        // 10000
      ).subscribe(
        (resp) => {
          playBeepSound(resp.content);
          setTotalPageElements(resp.totalElements);
          setData(resp.content);
          setAppointments(resp.content);
          setLoading(false);
          if (callNext) {
            setCallNext(false);
          }
        },
        () => {
          setLoading(false);
        }
      );
      return () => appntmentSubscription.unsubscribe();
    } else {
      const appntmentSubscription = DataService.polledFetchList(
        "appointment/search",
        // `get_appointment`,
        page,
        pageSize,
        filter,
        sortFilter
        // 10000
      ).subscribe(
        (resp) => {
          playBeepSound(resp.content);
          setTotalPageElements(resp.totalElements);
          setData(resp.content);
          setAppointments(resp.content);
          setLoading(false);
          if (callNext) {
            setCallNext(false);
          }
        },
        () => {
          setLoading(false);
        }
      );
      return () => appntmentSubscription.unsubscribe();
    }
  };

  const fetchCount = () => {
    // setLoading(true);

    if (
      (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
      (showRecord === false ||
        selectedDoctor !== null ||
        selectedDoctor !== "" ||
        selectedDoctor !== undefined)
    ) {
      const appntmentSubscription = DataService.polledFetchList(
        "appointment/counts",
        // `get_appointment`,
        page,
        pageSize,
        filter,
        sortFilter
        // 10000
      ).subscribe(
        (resp) => {
          setTotalElements(resp?.allCount);
          setTotalCancelledElements(resp?.cancelledCount);
          setTotalCheckedElements(resp?.checkedInCount);
          setTotalAdmittedElements(resp?.admitCount);
        },
        () => {
          setLoading(false);
        }
      );
      return () => appntmentSubscription.unsubscribe();
    } else {
      const appntmentSubscription = DataService.polledFetchList(
        "appointment/counts",
        // `get_appointment`,
        page,
        pageSize,
        filter,
        sortFilter
        // 10000
      ).subscribe(
        (resp) => {
          setTotalElements(resp?.allCount);
          setTotalCancelledElements(resp?.cancelledCount);
          setTotalCheckedElements(resp?.checkedInCount);
          setTotalAdmittedElements(resp?.admitCount);
        },
        () => {
           setLoading(false);
        }
      );
      return () => appntmentSubscription.unsubscribe();
    }
  };

  const getTheCurrentGroupBtnStep = (step) => {
    AuthService.setActiveTab(step);
    setActiveTab(step);
  };

  // const fetchTotalAppointment = () => {
  //   if (
  //     (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
  //     (showRecord === false ||
  //       selectedDoctor !== null ||
  //       selectedDoctor !== "" ||
  //       selectedDoctor !== undefined)
  //   ) {
  //     const totalAppointSubscription = DataService.polledFetchList(
  //       "appointment/search",
  //       page,
  //       pageSize,
  //       getFilterObj({
  //         date: currentDate,
  //         doctorSlug: selectedDoctor,
  //         patientSlug: selectedPatient,
  //         status: null,
  //       }),
  //       sortFilter,
  //       10000
  //     ).subscribe((resp) => {
  //       setTotalElements(resp.totalElements);
  //     });
  //     return () => totalAppointSubscription.unsubscribe();
  //   } else {
  //     const totalAppointSubscription = DataService.polledFetchList(
  //       "appointment/search",
  //       page,
  //       pageSize,
  //       getFilterObj({
  //         date: currentDate,
  //         doctorSlug: "",
  //         patientSlug: "",
  //         status: null,
  //       }),
  //       sortFilter,
  //       10000
  //     ).subscribe((resp) => {
  //       setTotalElements(resp.totalElements);
  //     });
  //     return () => totalAppointSubscription.unsubscribe();
  //   }
  // };

  // const fetchCheckedInAppointment = () => {
  //   if (
  //     (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
  //     (showRecord === false ||
  //       selectedDoctor !== null ||
  //       selectedDoctor !== "" ||
  //       selectedDoctor !== undefined)
  //   ) {
  //     const checkedAppointSubscription = DataService.polledFetchList(
  //       "appointment/search",
  //       page,
  //       pageSize,
  //       getFilterObj({
  //         date: currentDate,
  //         doctorSlug: selectedDoctor,
  //         patientSlug: selectedPatient,
  //         status: ["CHECKED_IN"],
  //       }),
  //       sortFilter,
  //       10000
  //     ).subscribe((resp) => {
  //       setTotalCheckedElements(resp.totalElements);
  //     });

  //     return () => checkedAppointSubscription.unsubscribe();
  //   } else {
  //     const checkedAppointSubscription = DataService.polledFetchList(
  //       "appointment/search",
  //       page,
  //       pageSize,
  //       getFilterObj({
  //         date: currentDate,
  //         doctorSlug: "",
  //         patientSlug: "",
  //         status: ["CHECKED_IN"],
  //       }),
  //       sortFilter,
  //       10000
  //     ).subscribe((resp) => {
  //       setTotalCheckedElements(resp.totalElements);
  //     });

  //     return () => checkedAppointSubscription.unsubscribe();
  //   }
  // };

  // const fetchCancelledAppointment = () => {
  //   if (
  //     (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
  //     (showRecord === false ||
  //       selectedDoctor !== null ||
  //       selectedDoctor !== "" ||
  //       selectedDoctor !== undefined)
  //   ) {
  //     const cancelledAppointSubscription = DataService.polledFetchList(
  //       "appointment/search",
  //       page,
  //       pageSize,
  //       getFilterObj({
  //         date: currentDate,
  //         doctorSlug: selectedDoctor,
  //         patientSlug: selectedPatient,
  //         status: ["CANCELLED", "NO_SHOW"],
  //       }),
  //       sortFilter,
  //       10000
  //     ).subscribe((resp) => {
  //       setTotalCancelledElements(resp.totalElements);
  //     });
  //     return () => cancelledAppointSubscription.unsubscribe();
  //   } else {
  //     const cancelledAppointSubscription = DataService.polledFetchList(
  //       "appointment/search",
  //       page,
  //       pageSize,
  //       getFilterObj({
  //         date: currentDate,
  //         doctorSlug: "",
  //         patientSlug: "",
  //         status: ["CANCELLED", "NO_SHOW"],
  //       }),
  //       sortFilter,
  //       10000
  //     ).subscribe((resp) => {
  //       setTotalCancelledElements(resp.totalElements);
  //     });
  //     return () => cancelledAppointSubscription.unsubscribe();
  //   }
  // };

  const playBeepSound = (data) => {
    if (
      (user.roleName === AppConstants.ROLE.DOCTOR ||
        user.roleName === AppConstants.ROLE.DOCTORADMIN) &&
      !checkinAudioStatus
    ) {
      return data?.map((resp) => {
        let key = resp.slug;
        if (resp.called) {
          if (localStorage.getItem(key)) {
            return null;
          } else {
            return null;
          }
        }

        if (
          resp.status === "CHECKED_IN" &&
          user.roleName === AppConstants.ROLE.DOCTOR &&
          resp.called === false &&
          checkinAudio
        ) {
          let doctorCalled = localStorage.getItem(`doctorCalled`);
          if (doctorCalled) {
            doctorCalled = JSON.parse(doctorCalled);
          } else {
            doctorCalled = {};
          }
          if (doctorCalled[key]) {
            return null;
          } else {
            localStorage.setItem(
              "doctorCalled",
              JSON.stringify({ ...doctorCalled, [key]: true })
            );
            return null;
          }
        }
        localStorage.removeItem(key);
        return null;
      });
    } else {
      return data?.map((resp) => {
        let key = resp.slug;
        if (resp.called) {
          if (localStorage.getItem(key)) {
            return null;
          } else {
            let audio = new Audio(soundFileDoorBell);
            audio.play();
            localStorage.setItem(key, resp.called);
            return null;
          }
        }

        if (
          resp.status === "CHECKED_IN" &&
          user.roleName === AppConstants.ROLE.DOCTOR &&
          resp.called === false &&
          checkinAudio
        ) {
          let doctorCalled = localStorage.getItem(`doctorCalled`);
          if (doctorCalled) {
            doctorCalled = JSON.parse(doctorCalled);
          } else {
            doctorCalled = {};
          }
          if (doctorCalled[key]) {
            return null;
          } else {
            playBeepSoundOnStatus();
            localStorage.setItem(
              "doctorCalled",
              JSON.stringify({ ...doctorCalled, [key]: true })
            );
            return null;
          }
        }
        localStorage.removeItem(key);
        return null;
      });
    }
  };

  // const fetchCurrentWeeksAppointment = () => {

  // const user = AuthService.getUser();

  //   let filter = moment().startOf('isoWeek').valueOf();
  //   let filterTo = moment(filter).add(7, 'days').valueOf();
  //   let filterModel = {
  //     date: { type: 'inRange', filter, filterTo, filterType: 'date', doctorSlug: `${user.slug}` },
  //   };
  //   DataService.fetchList(
  //     'appointment/search/all',
  //     0,
  //     0,
  //     filterModel,
  //     sortFilter
  //   ).subscribe((resp) => {
  //     setCalendarData(resp);
  //   });
  // };

  const [handlestatus, setHandleStatus] = useState(false);
  const handleChangeStatus = async (evt, row) => {
    setHandleStatus(!handlestatus);
    const value = evt?.target?.value;
    setAppointmentsStatus((prev) => ({ ...prev, [row?.slug]: value }));
    if((value === "REPORT_READY") && (row?.checkInTime == null)){
      SHOW_ERROR_NOTIFICATION("Patient must be checked in first");
      return;
    }
    // get the appointment details
    await Api.put(`appointment/status/${row?.slug}/${value}`).subscribe(
      (resp) => {
        setAppointments((prev) => {
          const data = JSON.parse(JSON.stringify(prev));
          const foundAppointment = data?.find(
            (appointment) => appointment?.slug === row?.slug
          );
          foundAppointment && (foundAppointment.status = value);
          return [...data];
        });
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const fetchDoctors = () => {
    DataService.getList("doctor/search/all").subscribe((resp) => {
      setAllDoctors(resp);
      setAllPatients(new Set(resp));
    });
  };

  useEffect(fetchAppointments, [
    page,
    filter,
    callNext,
    currentStatus,
    handlestatus,
    showRecord,
    toggle,
    refresh,
  ]);
  // useEffect(fetchCount, [page,filter, callNext, currentStatus, handlestatus,showRecord, toggle,refresh]);
  useEffect(fetchCount, [
    page,
    filter,
    callNext,
    currentStatus,
    handlestatus,
    showRecord,
    toggle,
    refresh,
  ]);

  //  useEffect(()=>{
  //   if (filter.date !== currentDate) {
  //     fetchCount()
  //   }
  //  },[page, filter, callNext, currentStatus, handlestatus, showRecord, toggle, refresh])

  // useEffect(fetchTotalAppointment, [page, filter, callNext, currentStatus, handlestatus, showRecord, toggle, refresh]);
  // useEffect(fetchCheckedInAppointment, [page, filter, callNext, currentStatus, handlestatus, showRecord, toggle, refresh]);
  // useEffect(fetchCancelledAppointment, [page, filter, callNext, currentStatus, handlestatus, showRecord, toggle, refresh]);

  useEffect(() => {
    // fetchCurrentWeeksAppointment();
    fetchDoctors();
    // fetchPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const toggleView = () => {
    setIsListView(!isListView);
  };

  const onTabClicked = (index) => {
    if (index !== 3) {
      setLoading(true);
    }
    setTabIndex(index);

    // Update the 'tab' in local storage
    sessionStorage.setItem("appointmentTab", index);

    switch (index) {
      case 0:
        setPage(0);

        setCurrentStatus(null);
        if (
          (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
          (showRecord === false ||
            selectedDoctor !== null ||
            selectedDoctor !== "" ||
            selectedDoctor !== undefined)
        ) {
          setFilter(
            getFilterObj({
              date: currentDate,
              doctorSlug: isPrimaryProfile ? selectedDoctor : switchedProfileInfo?.doctorSlug,
              patientSlug: selectedPatient,
              status: null,
            })
          );
        } else {
          setFilter(
            getFilterObj({
              date: currentDate,
              doctorSlug: isPrimaryProfile ? selectedDoctor : switchedProfileInfo?.doctorSlug,
              patientSlug: selectedPatient,
              status: null,
            })
          );
        }

        setSortFilter(getSortObj(sortOptionSelected));
        setTotalPageElements(totalElements);
        break;

      case 1:
        setPage(0);
        setCurrentStatus(["CHECKED_IN"]);
        if (
          (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
          (showRecord === false ||
            selectedDoctor !== null ||
            selectedDoctor !== "" ||
            selectedDoctor !== undefined)
        ) {
          setFilter(
            getFilterObj({
              date: currentDate,
              doctorSlug: isPrimaryProfile ? selectedDoctor : switchedProfileInfo?.doctorSlug,
              patientSlug: selectedPatient,
              status: ["CHECKED_IN"],
            })
          );
        } else {
          setFilter(
            getFilterObj({
              date: currentDate,
              doctorSlug: selectedDoctor,
              patientSlug: selectedPatient,
              status: ["CHECKED_IN"],
            })
          );
        }

        setSortFilter(getSortObj(sortOptionSelected));
        setTotalPageElements(totalCheckedElements);
        break;

      case 2:
        setPage(0);
        setCurrentStatus(["CANCELLED", "NO_SHOW"]);
        if (
          (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") &&
          (showRecord === false ||
            selectedDoctor !== null ||
            selectedDoctor !== "" ||
            selectedDoctor !== undefined)
        ) {
          setFilter(
            getFilterObj({
              date: currentDate,
              doctorSlug: isPrimaryProfile ? selectedDoctor : switchedProfileInfo?.doctorSlug,
              patientSlug: selectedPatient,
              status: ["CANCELLED", "NO_SHOW"],
            })
          );
        } else {
          setFilter(
            getFilterObj({
              date: currentDate,
              doctorSlug: selectedDoctor,
              patientSlug: selectedPatient,
              status: ["CANCELLED", "NO_SHOW"],
            })
          );
        }

        setSortFilter(getSortObj(sortOptionSelected));
        setTotalPageElements(totalCancelledElements);
        break;

      default:
        break;
    }
  };

  const handleAddNew = () => {
    history.push(Routes.NEW_APPOINTMENT);
  };

  const onReviewBtnClicked = (patientSlug, appointmentSlug) => {
    history.push(
      `${Routes.REPORT}?patientSlug=${patientSlug}&appointmentSlug=${appointmentSlug}`
    );
  };

  const onCallNextBtnClicked = (slug) => {
    Api.put(`appointment/call-next/${slug}`).subscribe(
      (resp) => {
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
        setCallNext(true);
        setTimeout(() => {
          handleOpenPrescription(slug);
        }, 2000);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleOpenPrescription = useCallback((patientSlug) => {
    window.open(`#!` + Routes.PRESCRIPTION + `?slug=${patientSlug}`, "_blank");
  }, []);

  const toggleRescheduleDialog = (values) => {
    setCurrentRowInfo(values);
    setToggle(!toggle);
    setApiStatus(!apiStatus);
  };

  const togglePrintLoadingDialog = () => {
    setIsPrintLoading(!printLoading);
  };

  const onViewPrescription = async (values) => {
    window.open(
      `/#!` + Routes.APPOINTMENTPRINT + `?slug=${values.slug}`,
      "_blank"
    );
  };

  const onViewEMR = (values) => {
    window.open(`/#!` + Routes.PRESCRIPTION + `?slug=${values.slug}`, "_blank");
  };

  const onNotifyClicked = (values) => {
    Api.post(`appointment/notify/${values.slug}`).subscribe(
      () => {
        SHOW_SUCCESS_NOTIFICATION("Notify Successfully");
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const onAddDiscount = (values) => {
    setCurrentRowInfo(values);
    setIsDiscountOpen(!isDiscountOpen);
  };

  const [receipModalOpen, setReceiptModalOpen] = useState(false);
  const handleReceiptModalOpen = () => setReceiptModalOpen(true);
  const handleReceiptModalClose = () => setReceiptModalOpen(false);

  const [invoice, setInvoice] = useState();
  const [patientSlug, setPatientSlug] = useState();

  const [receiptData, setReceiptData] = useState({
    fee: "",
    itemDescription: "",
    discount: "",
    AppointmentNumber: "",
  });

  const [receiptDate, setReceiptDate] = useState();

  const [printDateSelected, setPrintDateSelected] = useState();
  const [PrintingTime, setPrintingTime] = useState(
    dayjs("2014-08-18T21:11:54")
  );
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(true);

  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };

  useEffect(() => {
    setReceiptData({
      fee: invoice?.appointment?.fees,
      itemDescription: "Consultation charges",
      discount: invoice?.appointment?.discount,
      AppointmentNumber: invoice?.appointment?.slug,
    });
    setReceiptDate(dayjs());
    setPrintDateSelected(dayjs());
    setPrintingTime();
  }, [invoice]);

  const handleReceiptData = (e) => {
    setReceiptData({ ...receiptData, [e.target.name]: e.target.value });
  };

  const onReceiptDateSelected = (timeString) => {
    setReceiptDate(timeString);
  };

  const onPrintDateSelected = (timeString) => {
    setPrintDateSelected(timeString);
  };

  const [defaultTime, setDefaultTime] = useState(dayjs());

  const onSelectPrintingTIme = (timeString) => {
    const current = new Date();
    // By default US English uses 12hr time with AM/PM
    const time = current.toLocaleTimeString("en-US");
    setPrintingTime(format(timeString?.$d, "HH:mm:ss"));
    setDefaultTime(timeString);
  };

  const onPrintReceipt = async (values) => {
    setPatientSlug(values.slug);
    await Api.get(`appointment/receipt/print/${values.slug}`).subscribe(
      (resp) => {
        setInvoice(resp?.data?.data);
      }
    );

    if (values?.order?.totalBill != null) {
      window.open(`#!` + `${Routes.BILLING}/slug=${values.slug}`, "_blank");
    } else {
      handleReceiptModalOpen();
    }
  };

  const saveReceipt = () => {
    Api.put(`order/receipt/true/${invoice?.appointment?.slug}`).subscribe(
      (resp) => {
        history.push({
          // pathname: Routes.RECEIPTPRINT,
          pathname: `${Routes.RECEIPTPRINT}`,
          search: `?slug=${invoice?.appointment?.slug}`,
          state: {
            receiptData: receiptData,
            printDateSelected: printDateSelected,
            PrintingTime: defaultTime?.format("HH:mm") ?? dayjs().format("HH:mm"),
            receiptDate: receiptDate,
            slug: invoice?.appointment?.slug,
            custom: isChecked,
            copy: isChecked2,
          },
        });
        // window.open(`/#!` + Routes.RECEIPTPRINT + `?slug=${invoice?.appointment?.slug}?`, '_blank')
      }
    );
  };

  const onPrintLetterHeadClicked = (values) => {
    // setIsPrintLoading(true);
    // DataService.printLetterHead(values.slug).subscribe(
    //   (resp) => {
    //     setIsPrintLoading(false);
    //     if (resp.data.letterHeadPdf) {
    //       window.open(resp.data.letterHeadPdf, '_blank');
    //     }
    //   },
    //   (error) => {
    //     setIsPrintLoading(false);
    //     DEFAULT_ERROR_CALLBACK(error);
    //   }
    // );

    window.open(
      `/#!` + Routes.APPOINTMENTPRINTHEAD + `?slug=${values.slug}`,
      "_blank"
    );
  };

  const playBeepSoundOnStatus = () => {
    let audio = new Audio(soundFileDoorBell);
    audio.play();
  };

  const toggleLateLeaveDialog = () => {
    setButtonToggle(!btnToggle);
  };

  const toggleAddBtnDialog = () => {
    setIsDiscountOpen(!isDiscountOpen);
  };

  const handleChangePage = (evt, newPage) => {
    setPage(newPage);
  };

  const onSortOptionSelected = (e) => {
    const value = e.target.value;

    setSortOptionSelected(value);

    setFilter(
      getFilterObj({
        date: currentDate,
        doctorSlug: selectedDoctor,
        patientSlug: selectedPatient,
        status: currentStatus,
      })
    );

    setSortFilter(getSortObj(value));
  };

  const onSearch = (val) => {
    setFilter({ _: { filterType: "text", type: "", filter: val.search } });
  };

  const onReset = () => {
    setPage(0);
    setCurrentStatus(null);
    setFilter(getFilterObj({ date: currentDate, status: null }));
  };

  const onPatientSelected = (val) => {
    setSelectedPatient(val);
    setFilter((prev) => ({
      ...prev,
      patientSlug: val ? { filterType: "set", values: [val] } : null,
    }));
  };

  const onAppnmtDateSelected = (momentDate) => {
    const dateInEpoch = momentDate.valueOf();

    setCurrentDate(dateInEpoch);
    if (momentDate.isValid()) {
      setFilter((prev) => ({
        ...prev,
        date: {
          type: "equals",
          filter: dateInEpoch,
          filterType: "date",
        },
      }));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if(refresh === null) setRefresh(false)
      else setRefresh(!refresh);
    }, 10000);
  }, [refresh]);

  const rows = useMemo(() => {
    let filteredData = data;
    if (sortFilter[0]?.colId === "dateTime") {
      filteredData = orderBy(
        data,
        (a) => {
          const date = moment(a.date).format("YYYY-MM-DD");
          return moment(`${date} ${a.time}`, '"YYYY-MM-DD h:mm a');
        },
        "asc"
      );
    }
    return filteredData;
  }, [data, sortFilter, apiStatus, refresh]);

  const appointmentDashboardPage = location.pathname !== "/app/dashboard";

  const openCurrentActiveTabView = () => {
    switch (activeTab) {
      case 1:
        return (
          <Calendar
            toggleSideTab={appntmentCntxt.toggleSideTab}
            events={calendarData}
          />
        );
      case 2:
        return (
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {appointmentDashboardPage && (
                <div style={{ display: "flex", width: "100%" }}>
                  <Tab
                    key={0}
                    index={0}
                    currentIndex={tabIndex}
                    title={`All Appointments : ${totalElements}`}
                    onTabClicked={onTabClicked}
                  />
                  <Tab
                    key={1}
                    index={1}
                    currentIndex={tabIndex}
                    title={`Checked In : ${totalCheckedElements}`}
                    onTabClicked={onTabClicked}
                  />
                  <Tab
                    key={2}
                    index={2}
                    currentIndex={tabIndex}
                    title={`Cancelled/No Show : ${totalCancelledElements}`}
                    onTabClicked={onTabClicked}
                  />
                </div>
              )}
              {totalElements > 0 && appointmentDashboardPage && (
                <div
                  style={{
                    display: "flex",
                    marginRight: "12px",
                    alignItems: "flex-start",
                    width: "15%",
                  }}
                ></div>
              )}
            </Box>

            <AppointmentsCardSection
              rows={rows}
              onReviewBtnClicked={onReviewBtnClicked}
              handleChangeStatus={handleChangeStatus}
              onQueueUpdateClicked={appntmentCntxt.onQueueUpdateClicked}
              onPrintLetterHeadClicked={onPrintLetterHeadClicked}
              onCallNextBtnClicked={onCallNextBtnClicked}
              onViewPrescriptionClicked={onViewPrescription}
              onAddDiscountClicked={onAddDiscount}
              onPrintReceiptClicked={onPrintReceipt}
              onViewEMRClicked={onViewEMR}
              onNotifyClicked={onNotifyClicked}
              handleOpenPrescription={handleOpenPrescription}
            />
          </div>
        );

      default:
        return (
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {appointmentDashboardPage && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Tab
                    key={0}
                    index={0}
                    currentIndex={tabIndex}
                    title={`All Appointments : ${totalElements}`}
                    onTabClicked={onTabClicked}
                  />
                  <Tab
                    key={1}
                    index={1}
                    currentIndex={tabIndex}
                    title={`Checked In : ${totalCheckedElements}`}
                    onTabClicked={onTabClicked}
                  />
                  <Tab
                    key={2}
                    index={2}
                    currentIndex={tabIndex}
                    title={`Cancelled/No Show : ${totalCancelledElements}`}
                    onTabClicked={onTabClicked}
                  />
                  {/* <Tab
                    key={3}
                    index={3}
                    currentIndex={tabIndex}
                    title={`Admited : ${admittedcounts}`}
                    onTabClicked={onTabClicked}
                  /> */}
                  <Tab
                    key={3}
                    index={3}
                    currentIndex={tabIndex}
                    title={`Admitted : ${totalAdmittedElements ?? 0}`}
                    onTabClicked={onTabClicked}
                  />

                  {isPrimaryProfile && tabIndex !== 3 && <CustomTooltip
                    title="Update no show status"
                    style={{ margin: "0px 4px" }}
                  >
                    <IconButton
                      className={classes.iconButton}
                      onClick={updateNoShow}
                    >
                      <UpdateIcon />
                    </IconButton>
                  </CustomTooltip>}
                  {isPrimaryProfile && tabIndex !== 3 && <div>
                    <React.Fragment>
                      <Button variant="outlined" onClick={handleClickOpenOne}>
                        Notify
                      </Button>
                      <Dialog
                        open={openOne}
                        onClose={handleCloseOne}
                        PaperProps={{
                          component: "form",
                          onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(
                              formData.entries()
                            );
                            const email = formJson.email;
                            handleClose();
                          },
                        }}
                      >
                        <DialogTitle>Reminder Date</DialogTitle>
                        <DialogContent>
                          <Grid className="mt-5">
                            <DatePicker
                              label="Reminder Date (DD/MM/YYYY)"
                              value={notifyDate}
                              onChange={(newValue) => {
                                handleDateChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  style={{ width: "20rem", height: "10rem" }}
                                />
                              )}
                              className={globalClass.roundWhiteBg}
                              format="DD/MM/YYYY"
                            />
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseOne}>Cancel</Button>
                          <Button
                            type="submit"
                            onClick={handleSubmitNotifyDate}
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </React.Fragment>
                  </div>}
                </div>
              )}
            </Box>
            {loading ? (
              <Loader height="60vh" />
            ) : tabIndex === 0 || tabIndex === 1 || tabIndex === 2 ? (
              <AppointmentList
                page={page}
                pageSize={pageSize}
                count={totalPageElements}
                rows={rows}
                onReviewBtnClicked={onReviewBtnClicked}
                handleChangeStatus={handleChangeStatus}
                handleChangePage={handleChangePage}
                onPrintLetterHeadClicked={onPrintLetterHeadClicked}
                onQueueUpdateClicked={appntmentCntxt.onQueueUpdateClicked}
                onCallNextBtnClicked={onCallNextBtnClicked}
                onDateTimeClicked={toggleRescheduleDialog}
                onViewPrescriptionClicked={onViewPrescription}
                onAddDiscountClicked={onAddDiscount}
                onPrintReceiptClicked={onPrintReceipt}
                onViewEMRClicked={onViewEMR}
                onNotifyClicked={onNotifyClicked}
                handleOpenPrescription={handleOpenPrescription}
                handleOpen={handleOpen}
                handlenofify={handlenofify}
                setApiStatus={setApiStatus}
              />
            ) : tabIndex === 3 ? (
              <AdmittedList
                doctorSlug={selectedDoctor}
                currentdate={currentDate}
                patientSlug={selectedPatient}
              />
            ) : null}
          </div>
        );
    }
  };

  const updateNoShow = () => {
    Api2.put("/check_for_no_show").subscribe(
      (resp) => {
        if (resp.data.status == false) {
          SHOW_ERROR_NOTIFICATION(resp.data.message);
        } else {
          SHOW_SUCCESS_NOTIFICATION(resp.data.message);
          // setTimeout(()=>{
          //   window.location.reload();
          // },2000)
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const [mail, setMail] = React.useState({
    subject: "",
    content: "",
  });

  const handleChangeMail = (e) => {
    const { value, name } = e.target;
    setMail({ ...mail, [name]: value });
  };
  const handleSubmitMail = async (values) => {
    const formData = new FormData();
    formData.append("slug", patientSlugmodal);
    formData.append("subject", mail?.subject);
    formData.append("content", mail?.content);
    const token = AuthService.getToken();
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_HOST}patient/send_custom_msg`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-AUTH-TOKEN": token,
          },
        }
      );
      if (res) {
        SHOW_SUCCESS_NOTIFICATION(res.data.message);
        setOpenNotify(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {appointmentDashboardPage && (
        <Header
          title="Appointment"
          isListView={isListView}
          {...(isListView && {
            sortingOptions,
            sortOptionSelected,
            onSortOptionSelected,
          })}
          {...(allDoctors && { doctors: allDoctors, onDoctorSelected })}
          {...(allPatients && { patients: allPatients, onPatientSelected })}
          // onPatientSelected={onPatientSelected}
          appnmtDate={currentDate}
          onAppnmtDateSelected={onAppnmtDateSelected}
          onAddNew={
            isAuthorised(
              AppConstants.ENTITY.APPOINTMENT,
              AppConstants.ACCESS.ADD
            ) && handleAddNew
          }
          handleClickGroupBtn={getTheCurrentGroupBtnStep}
          onGroupBtnClick={toggleView}
          handleOpen={handleOpen}
          handlenofify={handlenofify}
          handleClose={handleClose}
          open={open}
        />
      )}

      <Box m={2}>{openCurrentActiveTabView()}</Box>
      {toggle && (
        <RescheduleDialog
          handleClose={toggleRescheduleDialog}
          open={toggle}
          appointmentObj={currentRowInfo}
          setAppointments={setData}
          setApiStatus={setApiStatus}
        />
      )}
      {btnToggle && (
        <LeaveLateDialog handleClose={toggleLateLeaveDialog} open={btnToggle} />
      )}
      {isDiscountOpen && (
        <AddDiscountDialog
          handleClose={toggleAddBtnDialog}
          open={isDiscountOpen}
          appointmentObj={currentRowInfo}
        />
      )}
      {printLoading && (
        <PrintInfoDialog
          handleClose={togglePrintLoadingDialog}
          open={printLoading}
        />
      )}
      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <CloseIcon onClick={handleClose} style={{ float: "right", cursor: "pointer" }} />
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Custom_Status
            </Typography>
            <Grid>
              <TextField
                id="outlined-basic"
                label="Custom_Status"
                variant="outlined"
                field="text"
                value={customLoge}
                placeholder="Custom_Status"
                className={classes.style_1}
                onChange={handleCustomLoge}
              />
            </Grid>
            <Box
              mb={3}
              style={{ marginTop: "2%", padding: "1%", float: "right" }}
            >
              {/* <Button
          type="submit"
          onClick={handleClose}
          variant="contained"
          color="secondary"
          className={globalClass.mediumBtnContainer}
          style={{marginRight:'17px'}}
        >
          Cancel
        </Button>  */}

              <Button
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                className={globalClass.mediumBtnContainer}
              >
                {addLoadingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "SAVE"
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      }

      {
        <Modal
          open={open_notify}
          onClose={handleClosenotify}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <CloseIcon onClick={handleClosenotify} style={{ float: "right", cursor: "pointer" }} />
            <Typography variant="h6" className={classes.prescriptionTitle}>
              Notifcation
            </Typography>
            <Grid>
              <TextField
                autoFocus
                required
                margin="dense"
                id="subject"
                value={mail.subject}
                onChange={handleChangeMail}
                name="subject"
                label="Subject"
                type="text"
                fullWidth
                variant="standard"
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="content"
                name="content"
                onChange={handleChangeMail}
                value={mail.content}
                label="Content"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Box
              mb={3}
              style={{ marginTop: "2%", padding: "1%", float: "right" }}
            >
              <Button
                type="submit"
                onClick={handleSubmitMail}
                variant="contained"
                color="primary"
                className={globalClass.mediumBtnContainer}
              >
                {addLoadingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "SAVE"
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      }

      {receipModalOpen && (
        <Modal
          open={receipModalOpen}
          onClose={handleReceiptModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Receipt
              </Typography>
              <CloseIcon
                onClick={handleReceiptModalClose}
                style={{ float: "right", cursor: "pointer" }}
              />
            </Box>

            {invoice ? (
              <>
                <Grid style={{ marginTop: "3%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Fees"
                    variant="outlined"
                    field="text"
                    value={receiptData.fee}
                    placeholder="Fees"
                    name="fee"
                    className={classes.style_1}
                    onChange={handleReceiptData}
                  />
                </Grid>

                <Grid style={{ marginTop: "3%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Item Description"
                    variant="outlined"
                    field="text"
                    value={receiptData.itemDescription}
                    placeholder="Item Description"
                    name="itemDescription"
                    className={classes.style_1}
                    onChange={handleReceiptData}
                  />
                </Grid>

                <Grid style={{ marginTop: "3%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Discount"
                    variant="outlined"
                    field="text"
                    name="discount"
                    value={receiptData.discount}
                    placeholder="Discount"
                    className={classes.style_1}
                    onChange={handleReceiptData}
                  />
                </Grid>

                <Grid style={{ marginTop: "3%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Appointment Number"
                    variant="outlined"
                    field="text"
                    name="AppointmentNumber"
                    value={receiptData.AppointmentNumber}
                    placeholder="Appointment Number"
                    className={classes.style_1}
                    onChange={handleReceiptData}
                  />
                </Grid>

                <Grid
                  style={{ marginTop: "3%", width: "100%", marginLeft: "2%" }}
                >
                  <DatePicker
                    label="Receipt Date (DD/MM/YYYY)"
                    value={receiptDate}
                    onChange={(newValue) => {
                      onReceiptDateSelected(newValue);
                    }}
                    format="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    className={globalClass.roundWhiteBg}
                  />
                </Grid>

                <Grid
                  style={{
                    marginTop: "3%",
                    width: "100%",
                    marginLeft: "2%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <DatePicker
                    label="Printing Date (DD/MM/YYYY)"
                    value={printDateSelected}
                    onChange={(newValue) => {
                      onPrintDateSelected(newValue);
                    }}
                    className={globalClass.roundWhiteBg}
                    format="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  <TimePicker
                    label="Printing Time"
                    value={defaultTime}
                    onChange={(newValue) => {
                      onSelectPrintingTIme(newValue);
                    }}
                    className={globalClass.roundWhiteBg}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>

                <FormGroup>
                  {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={isChecked2}
                        onChange={handleCheckboxChange2}
                      />
                    }
                    label="Duplicate"
                  />
                  {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                </FormGroup>

                <Grid>
                  <Box my={6} display="flex" justifyContent="center">
                    <Button
                      style={{ marginRight: 12, padding: 8 }}
                      variant="outlined"
                      onClick={saveReceipt}
                    >
                      Proceed to Print Receipt
                    </Button>
                  </Box>
                </Grid>
              </>
            ) : (
              <Loader width="10px" />
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Appointment;
